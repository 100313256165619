const projects = [
  {
    url: "https://treelightholdings.com/",
    name: "TreelightHoldings.com",
    info: "A completely custom, fully responsive website built based on the client's figma design",
    tech: `
"ReactJS",
"styled-components",
"vite",
"vercel",
"cloudflair"`,
  },
  {
    url: "https://yosef-url-shortner2.vercel.app/",
    name: "URL Shortner",
    info: "A functional, fully responsive demo page I created to demonstrate pixel-perfect website creation.",
    tech: `"ReactJS", "Material-ui"`,
  },
  {
    url: "https://sophont.tech/",
    name: "Sophont Technology LLC",
    info: "Landing page for my new LLC focused on website creation and design.",
    tech: `"ReactJS", "EmailJS", "Webpack", "Babel"`,
  },
  {
    url: "https://github.com/H3RSKO/SerialContributor",
    name: "SerialContributor",
    info: "An opensource tool to help new developers make their first opensource contributions. Read more about it on Medium.",
    tech: `"NodeJS", "Puppeteer", "Express", "Postgres", "Sequelize"`,
  },
  {
    url: "https://www.vividhues.online/",
    name: "vividhues.online",
    info: "A landing page and gallery for a local art company",
    tech: `"ReactJS", "Vercel", "Bootstrap", "react-images"`,
  },
  {
    url: "https://myresume.tech/",
    name: "MyResume.tech",
    info: "A portfolio website showcasing my current, planned, and completed projects",
    tech: `"ReactJS", "Bootstrap", "animate.css", "Octicon"`,
  },
];

export default projects;
