import React, { Component } from "react";
import "./App.css";
import Octicon, {
  PrimitiveDot,
  MarkGithub,
} from "@githubprimer/octicons-react";
import { ProjectTech } from "./components/ProjectTech";
import projects from "./data/data";
import { ProjectName } from "./components/ProjectName";
import { ProjectInfo } from "./components/ProjectInfo";

class App extends Component {
  render() {
    return (
      <div className="jumbotron mb-0">
        <nav className="navbar">
          <a className="navbar-brand" style={{ color: "rgb(33,85,205)" }}>
            > Yosef Herskovitz
            <b className="animated infinite flash slow">|</b>{" "}
          </a>
        </nav>
        <div className="container-fluid">
          <div className="row justify-content-around">
            <div className="col-md-6 mb-5">
              <div className="terminal1">
                <div className="terminal1-header">
                  <div className="header-buttons">
                    <Octicon icon={PrimitiveDot} size="medium" />
                    <Octicon icon={PrimitiveDot} size="medium" />
                    <Octicon icon={PrimitiveDot} size="medium" />
                  </div>
                </div>
                <div className="terminal1-body">
                  <p className="card-text-user">> yosef.role.current</p>
                  <p className="card-text-result">
                    "Senior Fullstack Software Engineer at Hearst"
                  </p>
                  <p className="card-text-user">> yosef.skills</p>
                  <p className="card-text-result">
                    ["JavaScript", "React", "Typescript", "Redux", "Vite",
                    "Express", "NodeJS", "TailwindCSS", "Jotai", "PostgreSQL",
                    "Material-UI"]
                  </p>
                  <p className="card-text-user"> yosef.education</p>
                  <p className="card-text-result">
                    "Fullstack Software Engineer: Fullstack Academy, New York,
                    NY"
                  </p>
                  <p className="card-text-result">
                    {" "}
                    "BA, Finance: Touro College, Kew Garden Hills, NY" <br />
                    "Graduated Cum Laude, Jan 2017"
                  </p>
                  <p className="card-text-result"></p>
                  <p className="card-text-user">> yosef.location</p>
                  <p className="card-text-result">"Rockland County, NY"</p>
                  <p className="card-text-user">
                    > <span className="animated infinite flash slow">█</span>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="terminal2">
                <div className="terminal2-header">
                  <div className="header-buttons">
                    <Octicon icon={PrimitiveDot} size="medium" />
                    <Octicon icon={PrimitiveDot} size="medium" />
                    <Octicon icon={PrimitiveDot} size="medium" />
                  </div>
                </div>
                <div className="terminal2-body">
                  <p className="card-text-user2"> previous.projects</p>
                  {projects.map(({ url, name: projectName, info, tech }) => (
                    <div className="card-text-result" key={url}>
                      <ProjectName name={projectName} url={url} />
                      <ProjectInfo info={info} />
                      <ProjectTech tech={tech} />
                    </div>
                  ))}
                  <p className="card-text-user">
                    <span className="animated infinite flash slow">█</span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div
              className="navbar-external-references"
              style={{ color: "rgb(33,85,205)" }}
            >
              <a
                className="github"
                href="https://github.com/H3RSKO"
                target="_blank"
                rel="noopener noreferrer"
                style={{ paddingRight: "7px" }}
              >
                <Octicon
                  icon={MarkGithub}
                  size="large"
                  verticalAlign="middle"
                />
              </a>
              <a
                className="linkedin"
                href="https://www.linkedin.com/in/yosef-herskovitz/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ paddingRight: "6px" }}
              >
                <img
                  src={require("./static/linkedinLogo.png")}
                  width="64"
                  height="64"
                  alt=""
                />
              </a>
              <a
                className="gitlab"
                href="https://gitlab.com/H3RSKO?nav_source=navbar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("./static/GitLab_Logo.png")}
                  width="64"
                  height="64"
                  alt=""
                />
              </a>
              <a
                className="npm"
                href="https://www.npmjs.com/~yosefh"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("./static/npm_logo.png")}
                  width="90"
                  height="64"
                  alt=""
                />
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
