import React from "react";

export const ProjectName = ({ name, url }) => {
  return (
    <p className="projects">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <b style={{ color: "rgb(16,40,96)" }}>{name}:</b>
      </a>
    </p>
  );
};
