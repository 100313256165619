import React from 'react';

const Transcript = () => {
  return (
    <div style={{"text-align": "center" }}>
      <div>
      ¯\_(ツ)_/¯
      </div>
      <img src={require("./static/transcript.png")} alt="" />

      <div>This is where we are holding. I'm seeing if I can get one but i'm not sure if it possible.</div>
    </div>
  )
}

export default Transcript;
